import React from "react"
import {
  Heading,
  Button,
  Label,
  Input,
  Textarea,
  Text
} from "theme-ui"

const Contact = () => {
  return (
    <>
      <Heading as="h2" mb={4} sx={{ lineHeight: "1.45" }} id="contact-us">
        Contact us
      </Heading>

      <Text mb={5}>We inform you that we will only respond to requests that we think suit our current priorities. We apologise to all those who do not receive a reply. We appreciate the trouble you have taken in sending your information.</Text>

      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
      >

        <input type="hidden" name="form-name" value="contact" /> { // eslint-disable-line jsx-a11y/control-has-associated-label
        }
        <input type="hidden" name="bot-field" /> { // eslint-disable-line jsx-a11y/control-has-associated-label
        }

        <Label htmlFor="name" mb={2}>
          Name
        </Label>
        <Input id="name" name="name" required mb={3} />

        <Label htmlFor="email" mb={2}>
          Email
        </Label>
        <Input type="email" id="email" name="email" required mb={3} />

        <Label htmlFor="message" mb={2}>
          Message
        </Label>
        <Textarea name="message" id="message" required rows="6" mb={4} />

        <Button type="submit">Submit</Button>
      </form>
    </>
  )
}

export default Contact

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Box, Flex, Text, Heading } from "theme-ui"
import FieldsOfActivity from "../components/fields-of-activity"
import Image from "../components/image"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Investment Projects" />
    <Box my={6}>
      <Heading as="h1" mb={5} sx={{ lineHeight: "1.45", fontSize: [5, null, null, "40px"] }}>
        We are a British company that aims to invest in technically,
        economically and financially feasible projects in any area of the
        productive economy
      </Heading>
    </Box>
    <Flex sx={{ flexWrap: "wrap", flexDirection: ["column-reverse", null, "initial"] }}>
      <Box sx={{ width: ["100%", null, "50%"], pr: [0, null, 4], pb: [0, null, 4], pt: 4, pl: 0 }}>
        <Image />
      </Box>
      <Flex
        sx={{
          width: ["100%", null, "50%"],
          p: [0, null, 4],
          pr: 0,
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Heading as="h2" mb={4} id="our-proposal">
          Our proposal
        </Heading>
        <Text>
          We orient our company towards business niches that are rational in
          terms of economic budgetary analysis and common sense and which,
          according to the empirical wisdom of experienced investors, provide
          value to our company while allowing us to contribute, within our
          modest scope, to improving the economic and developmental ratios of
          those countries where we invest.
        </Text>
      </Flex>
    </Flex>

    <Box my={6}>
      <Heading as="h2" mb={4} sx={{ lineHeight: "1.45" }}>
        Our activity is not oriented towards the purely financial field, but
        towards those sectors that form part of the physical economy
      </Heading>
      <Text>
        In these times of international economic instability among countries, in
        which some areas resist recession, others grow and yet others see their
        GDP shrink and show signs of decadence and even economic depression,
        opportunities for successful investment projects appear.
      </Text>
    </Box>

    <Box my={6}>
      <Heading as="h2" mb={4} sx={{ lineHeight: "1.45" }} id="fields-of-activity">
        Fields of activity
      </Heading>

      <FieldsOfActivity />
    </Box>

    <Box my={6}>
      <Contact />
    </Box>
  </Layout>
)

export default IndexPage

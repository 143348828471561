import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
/** @jsx jsx */
import { jsx, Box, Heading, Text, Grid } from "theme-ui"

const FieldsOfActivity = () => { //_withWebp
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "fields-of-activity" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 425, maxWidth: 425, quality: 85) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const fields = {
    "agrobusiness.jpg": {
      name: "Agrobusiness",
      text: "Crops that can be processed in the international market",
    },
    "gas-oil.jpg": {
      name: "Gas – Oil",
      text: "Liquefaction and regasification plants for natural gas.",
    },
    "industrial_sector.jpg": {
      name: "Industrial Sector",
      text: "Turnkey projects. Commodities Plants, petrochemicals, steel…",
    },
    "infrastructure.jpg": {
      name: "Infrastructure",
      text: "Ports, railway systems…",
    },
    "mining.jpg": {
      name: "Mining",
      text: "Underground assets as proven reserves",
    },
    "transports.jpg": {
      name: "Transports",
      text: "Build Operate Transfer projects",
    },
  }

  return (
    <Grid gap={[4, 4, 6]} columns={[1, 2]}>
      {data.allFile.edges.map(({ node }) => (
        <Box sx={{position: "relative",}}>
          <Img fluid={node.childImageSharp.fluid} />
          <Box sx={{
            p: 4,
            pl: [0, 0, 4],
            pb: [4, 4, 0],
            background: "white",
            position: ["inherit", "inherit", "absolute"],
            transform: ["none", "none", "translateY(-100%)"],
            width: ["100%", "100%", "85%"]
          }}>
            <Heading as="h3" pb={2} sx={{ lineHeight: "1.45" }}>
              {fields[node.childImageSharp.fluid.originalName].name}
            </Heading>
            <Text sx={{ maxWidth: 325 }}>
              {fields[node.childImageSharp.fluid.originalName].text}
            </Text>
          </Box>
        </Box>
      ))}
    </Grid>
  )
}

export default FieldsOfActivity
